@import "default/variables";
@import "default/breakpoints";
@import "default/base";
@import "default/text";
@import "default/form";
@import "default/portal";
@import "components/information-tooltip";
@import "components/input-image";
@import "components/form-navbar";

/** Global Magnolia + flexbox fix */
.mgnlEditorBar.area, .mgnlPlaceholder {
    width: 100% !important;

    &.mgnlEditorBarStatusIndicator {
        width: calc(100% - 28px) !important;
    }
}

