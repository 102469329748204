$logo-src: "../images/trimm-logo.svg" !default;

$font-size-base: 16px !default;
$font-family-base: sans-serif !default;
$font-family-headings: $font-family-base !default;

$background-color: #e7e7e7 !default;
$text-color-base: #575757 !default;

$primary-color: #0d709a !default;
$text-color-headings: $primary-color !default;

$link-color: $primary-color !default;

$button-background-submit: $primary-color !default;
$button-text-submit: #fff !default;
$button-background-reset: #fff !default;
$button-text-reset: $text-color-base !default;

$block-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
$border-radius: 5px;
