* {
    &, &:before, &:after {
        box-sizing: border-box;
    }
}

html {
    font-size: $font-size-base;
}

body {
    margin: 0;
    background-color: $background-color;
    font-family: $font-family-base;
    color: $text-color-base;
}

.logo {
    background: {
        image: url($logo-src);
        size: contain;
        position: center;
        repeat: no-repeat;
    }
    height: 70px;
    margin: 0 auto;
    //width: 300px;
}

.container {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px 40px;
    background-color: #fff;
    width: 100%;
}


#body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#footer {
    padding: 0;
    width: 100%;
    height: 40px;
    margin-top: 30px;

    p.copyright {
        text-align: center;
    }
}

//
//.registration-page {
//  & #main {
//    width: 520px;
//    margin: auto;
//    padding: 10px;
//    background-color: #ffffff;
//
//
//    //@media (max-width: $small) {
//    //  width: 100%;
//    //  padding:0px;
//    //}
//
//  }
//}
//
//.enrolment-page {
//  & #main {
//    width: 820px;
//    margin: auto;
//    padding: 10px;
//    background-color: #ffffff;
//
//    //@media (max-width: $medium) {
//    //  width: 100%;
//    //  padding:0px;
//    //}
//  }
//}
//


