
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    color: $text-color-headings;
    font-family: $font-family-headings;
}

a {
    color: $link-color;

    &:hover {
        color: darken($link-color, 10%);

    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.loginError {
    padding: 0px 20px 0px 20px;

    & p {
        color: red;
        font-weight: 600;
    }
}

.errorOnSave {
    padding: 12px;

    & p {
        color: red;
        font-weight: 900;
    }
}
