*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  background-color: #e7e7e7;
  font-family: sans-serif;
  color: #575757;
}

.logo {
  background-image: url("../images/trimm-logo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 70px;
  margin: 0 auto;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: #fff;
  width: 100%;
}

#body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#footer {
  padding: 0;
  width: 100%;
  height: 40px;
  margin-top: 30px;
}
#footer p.copyright {
  text-align: center;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #0d709a;
  font-family: sans-serif;
}

a {
  color: #0d709a;
}
a:hover {
  color: #094e6b;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.loginError {
  padding: 0px 20px 0px 20px;
}
.loginError p {
  color: red;
  font-weight: 600;
}

.errorOnSave {
  padding: 12px;
}
.errorOnSave p {
  color: red;
  font-weight: 900;
}

fieldset {
  border: none;
}

.disabled-row .slot {
  background-color: grey;
}
.disabled-row .slot[class*=used-] {
  background-color: red;
}
.disabled-row .slot[class*=used-].myAppointmentSlot, .disabled-row .slot[class*=used-].myRAppointmentSlot {
  background-color: #2aabd2;
}

.legendaSlot {
  width: 15px;
  aspect-ratio: 1;
  margin: 0 5px 0 0;
  padding: 0;
  border-radius: 50rem;
}
.legendaSlot.red {
  background-color: red;
}
.legendaSlot.green {
  background-color: green;
}
.legendaSlot.blue {
  background-color: #2aabd2;
}
.legendaSlot.orange {
  background-color: orange;
}
.legendaSlot.grey {
  background-color: grey;
}
.legendaSlot.lightgray {
  background-color: lightgray;
}
.legendaSlot.darkseagreen {
  background-color: darkseagreen;
}

.slot {
  width: 20px;
  aspect-ratio: 1;
  background-color: green;
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 0;
}

.rutte.myRAppointmentSlot {
  background-color: #2aabd2;
}
.rutte.disabledRutteSlot {
  background-color: grey;
}

div[class*=used-] {
  background-color: red;
}
div[class*=used-].myAppointmentSlot {
  background-color: #2aabd2;
}

.selected-slot,
.selected-rutte-slot {
  background-color: orange !important;
}

.input-option {
  margin: 0.1rem 0;
}
.input-option input {
  cursor: pointer;
}

.longtext {
  display: -webkit-inline-box !important;
  width: 75% !important;
}

.input-fileupload {
  display: flex;
  margin: 0.5rem 0;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
}
.input-fileupload .label {
  display: block;
  margin: 0.1rem 0;
  flex: 1;
}
.input-fileupload input {
  flex: 2;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 7px 15px;
}
@media (max-width: 960px) {
  .input-fileupload {
    flex-flow: wrap;
  }
  .input-fileupload .label {
    flex-basis: 100%;
  }
}

.input-text {
  display: flex;
  margin: 0.5rem 0;
}
.input-text .label {
  display: block;
  margin: 0.1rem 0;
  flex: 1;
}
.input-text .bsnError {
  flex: 2;
}
.input-text input {
  flex: 2;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 7px 15px;
}
@media (max-width: 960px) {
  .input-text {
    flex-flow: wrap;
  }
  .input-text .label {
    flex-basis: 100%;
  }
}

.input-select {
  display: flex;
  margin: 0.5rem 0;
}
.input-select .label {
  display: block;
  margin: 0.1rem 0;
  flex: 1;
}
.input-select select {
  flex: 2;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 7px 15px;
}
@media (max-width: 960px) {
  .input-select {
    flex-flow: wrap;
  }
  .input-select .label {
    flex-basis: 100%;
  }
}

.input-checkbox,
.input-radio {
  display: flex;
  margin: 0.5rem 0;
}
.input-checkbox .label,
.input-radio .label {
  display: block;
  margin: 0.1rem 0;
  flex: 1;
}
.input-checkbox .input-options,
.input-radio .input-options {
  flex: 2;
}
@media (max-width: 960px) {
  .input-checkbox,
.input-radio {
    flex-flow: wrap;
  }
  .input-checkbox .label,
.input-radio .label {
    flex-basis: 100%;
  }
}

.view-only {
  display: flex;
  margin: 0.5rem 0;
}
.view-only .label {
  display: block;
  margin: 0.1rem 0;
  flex: 1;
}
.view-only .values {
  width: 600px;
  max-height: 400px;
}

.oneProfile {
  font-weight: bold;
}

label {
  cursor: pointer;
}

input, select, textarea {
  font-size: inherit;
  font-family: inherit;
  border-radius: 5px;
}

textarea {
  width: 100%;
}

.content-section {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}

.button-wrapper {
  padding: 20px 0;
}
.button-wrapper .loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #0d709a;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
  display: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input[type=submit],
input[type=reset],
input[type=button] {
  font-family: inherit;
  font-size: inherit;
  margin-top: 10px;
  padding: 7px 15px;
  border: 0 none;
  cursor: pointer;
  border-radius: 5px;
}

input[type=submit] {
  background-color: #0d709a;
  color: #fff;
}
input[type=submit]:hover {
  background-color: #094e6b;
}

input[type=reset],
input[type=button] {
  background-color: #fff;
  color: #575757;
}
input[type=reset]:hover,
input[type=button]:hover {
  background-color: #e6e6e6;
}

input[type=submit]:disabled,
input[type=reset]:disabled,
input[type=button]:disabled,
input[type=button]:disabled.uploadButton {
  background-color: #DFDFDF;
}
input[type=submit]:disabled:hover,
input[type=reset]:disabled:hover,
input[type=button]:disabled:hover,
input[type=button]:disabled.uploadButton:hover {
  background-color: #DFDFDF;
}

input[type=button].uploadButton {
  background-color: #0d709a;
  color: #fff;
  max-width: 200px;
  margin: 0 0 0 20px;
}
input[type=button].uploadButton:hover {
  background-color: #094e6b;
}

.fileInput {
  border: none;
}

#loginForm input[type=text], #loginForm input[type=password] {
  width: 100%;
  height: 40px;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding-left: 40px;
  background-color: transparent;
  font-size: 1em;
  border-radius: 5px;
}
#loginForm .form-row {
  position: relative;
}
#loginForm .form-row .input_img {
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
}
#loginForm .button-wrapper {
  margin-left: -12px;
}
#loginForm label {
  display: none;
}
#loginForm .odd {
  background-color: #9acfea;
}
#loginForm .required {
  display: none;
}
#loginForm a:link, #loginForm a:visited, #loginForm a:hover, #loginForm a:active {
  color: #555555;
}
#loginForm .registerForgotten {
  display: flex;
  flex-direction: row;
}
#loginForm .registerForgotten a {
  margin-right: 20px;
}
#loginForm .form-row-qr {
  width: 500px;
  margin: 0 auto;
  position: relative;
}
#loginForm .form-row-qr .input_img {
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
}
#loginForm .qr-container {
  width: 100%;
  display: flex;
}
#loginForm .qr-container canvas {
  margin: 0 auto;
}

.login-text {
  padding: 0px 30px;
}

#register-new-user input[type=password], #register-new-user input[type=text], #register-new-user input[type=email], #forget-password input[type=password], #forget-password input[type=text], #forget-password input[type=email], #reset-password input[type=password], #reset-password input[type=text], #reset-password input[type=email], #profiel-beheer input[type=password], #profiel-beheer input[type=text], #profiel-beheer input[type=email] {
  width: 100%;
  height: 40px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
  background-color: transparent;
  font-size: 1em;
}

.information-tooltip-right {
  float: right;
}

.overview table {
  width: 100%;
}
.overview td {
  width: 50%;
}
.overview .course {
  display: flex;
  justify-content: space-evenly;
}
.overview .title.profiel {
  margin: 18px 0 0;
}
.overview tr:nth-child(even) {
  background-color: #ffffff;
}
.overview tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.overview .column {
  padding: 7px 15px;
  width: 100%;
}
.overview .column h4 {
  padding: 7px 0px;
  margin: 0px;
}
.overview .column label {
  text-align: left;
}
.overview .column .center-block {
  text-align: center;
  width: 100%;
  display: block;
}

.course-config .title {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #0d709a;
  font-family: sans-serif;
  font-weight: 700;
  display: block;
}
.course-config select {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
}
.course-config .column select {
  width: 100%;
}
.course-config .course {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.course-config .course > input, .course-config .course > select {
  flex: 0 0 auto;
}
.course-config .column {
  padding: 0.5rem 1rem;
}
.course-config .column .center-block {
  text-align: center;
  width: 100%;
  display: block;
}
.course-config .column .headItem {
  font-weight: 700;
}
.course-config .centered {
  text-align: center;
}
.course-config .column.column-courses {
  flex: 3;
}
.course-config .column.column-traject {
  flex: 1;
}
.course-config .column.column-grade {
  flex: 1;
}
.course-config .column.column-checkbox {
  flex: 1;
}
.course-config .column.column-endgrade {
  flex: 1;
}
.course-config .column.column-endgrade-warning {
  flex: 1;
  background-color: rgba(186, 85, 32, 0.2);
}
.course-config .grade-warning {
  color: #ba5520;
  width: 100%;
  text-align: right;
}
.course-config .optCoursePoint {
  max-width: 400px;
  margin-left: -350px;
  overflow: hidden;
}
.course-config .hide-dots .optCoursePoint {
  display: none;
}
.course-config .hide-profile .title.configuratie.ng-binding {
  display: none;
}

@media only screen and (max-width: 780px) {
  .course-config .optCoursePoint {
    max-width: 250px;
    overflow: hidden;
  }
}
@media only screen and (max-width: 500px) {
  .container {
    padding: 20px 13px;
  }
  .course-config .column {
    max-width: 110%;
  }
  .course-config .centered {
    max-width: 40px;
  }
  .course-config .vak {
    max-width: 175px;
    padding: 2px 2px;
  }
  .course-config .cijfer {
    max-width: 75px;
  }
  .course-config .volgen {
    max-width: 65px;
  }
  .course-config .course select {
    max-width: 75px;
  }
  .course-config .column.column-courses {
    max-width: 175px;
  }
  .course-config .column.column-traject {
    padding: 2px 2px;
  }
  .course-config .column.column-grade {
    padding: 2px 2px;
  }
  .course-config .column.column-checkbox {
    max-width: 75px;
  }
  .course-config .optCoursePoint {
    display: none;
  }
}
.math-warning {
  color: #721c24;
}

@media only screen and (max-width: 350px) {
  .container {
    padding: 20px 13px;
  }
  .course-config .column {
    max-width: 110%;
  }
  .course-config .centered {
    max-width: 30px;
  }
  .course-config .vak {
    max-width: 170px;
    padding: 2px 2px;
  }
  .course-config .cijfer {
    max-width: 75px;
  }
  .course-config .volgen {
    max-width: 63px;
  }
  .course-config .course select {
    max-width: 75px;
  }
  .course-config .column.column-courses {
    max-width: 220px;
    padding: 0px;
  }
  .course-config .column.column-traject {
    padding: 2px 0px;
  }
  .course-config .column.column-grade {
    padding: 2px 0px;
  }
  .course-config .column.column-checkbox {
    max-width: 75px;
  }
  .course-config .optCoursePoint {
    display: none;
  }
}
.bdf.course-config .profileSelect {
  padding: 7px 15px;
}
.bdf.course-config .odd {
  background-color: lightgray;
}
.bdf.course-config .even {
  background-color: #ffffff;
}
.bdf.course-config .column {
  padding: 0px 0px;
  margin: 2px 4px;
}
.bdf.course-config .column.column-courses {
  flex: 3;
  display: flex;
  align-items: center;
}
.bdf.course-config .column.column-traject {
  flex: 2;
}
.bdf.course-config .column.column-grade {
  flex: 1;
}
.bdf.course-config .column.column-checkbox {
  flex: 1;
}
.bdf.course-config .column.column-endgrade {
  flex: 1;
}
.bdf.course-config select {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0px 10px 0px 0px;
}
.bdf.course-config input[type=text] {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 7px 15px;
  width: 100%;
}
@media (min-width: 960px) {
  .bdf.course-config #GemeenschappelijkdeelOptie7checkbox {
    max-width: 77px;
  }
}

.enrolment-page-courseOverview table {
  width: 100%;
}
.enrolment-page-courseOverview table th {
  text-align: left;
}
.enrolment-page-courseOverview table .profile {
  padding-top: 10px;
  background-color: #EFEFEF;
}

.enrolment-step .downloadFormContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.enrolment-step .downloadFormContainer .downloadForms {
  margin-right: 10px;
}
.enrolment-step .downloadNotification {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 112, 154, 0.8);
  align-items: center;
  display: none;
  justify-content: center;
}
.enrolment-step .downloadNotification .notificationContainer {
  justify-content: center;
  align-content: initial;
  align-items: center;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  flex-wrap: wrap;
}
.enrolment-step .downloadNotification .notificationContainer .notificationContent {
  overflow: auto;
  width: 750px;
  justify-content: center;
}
.enrolment-step .downloadNotification .notificationContainer .notificationContent .downloadNotificationTitle {
  display: flex;
  justify-content: center;
}
.enrolment-step .downloadNotification .notificationContainer .notificationButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.enrolment-step .downloadNotification .notificationContainer .notificationButtonContainer #closeNotification {
  background-color: #0d709a;
  color: white;
}

.enrolment-edit {
  /* The Modal (background) */
  /* Modal Content/Box */
}
.enrolment-edit .title {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #0d709a;
  font-family: sans-serif;
  font-weight: 700;
  display: block;
}
.enrolment-edit .studentInfo, .enrolment-edit .studentRemarks, .enrolment-edit .intake, .enrolment-edit .planning {
  display: flex;
  flex-direction: column;
}
.enrolment-edit .planning {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.enrolment-edit .informationRow {
  padding-bottom: 5px;
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: left;
  align-content: center;
  align-items: center;
}
.enrolment-edit .informationRow p {
  margin: 0;
}
.enrolment-edit .informationRow p:first-child {
  width: 200px;
}
.enrolment-edit .informationRow textarea, .enrolment-edit .informationRow input, .enrolment-edit .informationRow select {
  width: calc(100% - 200px);
  flex: 2;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 7px 15px;
}
.enrolment-edit .informationRow .deleteRemarkText {
  font-weight: bold;
  border: none;
  background-color: transparent;
}
.enrolment-edit .informationRow table {
  width: calc(100% - 200px);
}
.enrolment-edit .informationRow table td {
  padding: 0px 5px;
}
.enrolment-edit .informationRow table td:first-child {
  width: 100%;
}
.enrolment-edit .informationRow .warning {
  font-size: 14px;
  color: #ba5520;
  margin-top: 8px;
}
.enrolment-edit .informationRow .check {
  color: #008701;
}
.enrolment-edit .informationRow .cross {
  color: rgba(255, 0, 0, 0);
}
.enrolment-edit .informationRow .marginleft {
  margin-left: 15px;
}
.enrolment-edit .informationRow input, .enrolment-edit .informationRow select {
  width: auto;
}
.enrolment-edit .statusChoices {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 200px);
  padding-bottom: 5px;
}
.enrolment-edit .statusChoices .input-option {
  flex: 1 0 50%;
}
.enrolment-edit .statusListColumn {
  display: block;
  column-count: 2;
}
.enrolment-edit .statusListColumn .statusItem {
  padding-right: 5px;
  justify-items: flex-end;
}
.enrolment-edit .statusChangeModal {
  z-index: 2; /* Sit on top */
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(13, 112, 154, 0.8);
  align-items: center;
  display: none; /* Hidden by default */
  justify-content: center;
  overflow: auto; /* Enable scroll if needed */
}
.enrolment-edit .statusChangeModal-content {
  justify-content: center;
  align-content: initial;
  align-items: center;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  flex-wrap: wrap;
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 45%; /* Could be more or less, depending on screen size */
}
.enrolment-edit .statusChangeModal-content .statusChangeButtons {
  display: flex;
  justify-content: space-around;
  width: 500px;
}
.enrolment-edit .statusChangeModal-content .statusChangeButtons .statusChangeButton {
  background-color: #0d709a;
  color: #fff;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  margin-top: 10px;
  padding: 7px 15px;
}
.enrolment-edit .statusChangeModal-content .statusChangeButtons .statusChangeButton:hover {
  background-color: #094e6b;
}
.enrolment-edit hr {
  padding-top: 2px;
  padding-bottom: 2px;
  background: #0d709a;
  border: 0;
}
.enrolment-edit input[type=button] {
  background-color: #0d709a;
  color: #fff;
}
.enrolment-edit input[type=button]:hover {
  background-color: #094e6b;
}
.enrolment-edit input[type=submit]:disabled,
.enrolment-edit input[type=reset]:disabled,
.enrolment-edit input[type=button]:disabled,
.enrolment-edit input[type=button]:disabled.uploadButton {
  background-color: #DFDFDF;
}
.enrolment-edit input[type=submit]:disabled:hover,
.enrolment-edit input[type=reset]:disabled:hover,
.enrolment-edit input[type=button]:disabled:hover,
.enrolment-edit input[type=button]:disabled.uploadButton:hover {
  background-color: #DFDFDF;
}
.enrolment-edit .button-wrapper {
  display: flex;
  justify-content: space-between;
}
.enrolment-edit .mailTemplate {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 112, 154, 0.8);
  align-items: center;
  display: none;
  justify-content: center;
}
.enrolment-edit .mailTemplate .mailContainer {
  justify-content: center;
  align-content: initial;
  align-items: center;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  flex-wrap: wrap;
}
.enrolment-edit .mailTemplate .mailContainer .mailContent {
  overflow: auto;
  width: 750px;
  height: 500px;
  justify-content: center;
}
.enrolment-edit .mailTemplate .mailButtonContainer {
  display: flex;
  justify-content: space-around;
  width: 500px;
}

.form-row.error label span em {
  display: none;
}

.table {
  width: 100%;
  margin-top: 15px;
}
.table thead td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0d709a;
  color: white;
}
.table thead td a {
  color: white;
}
.table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.table tr:hover {
  background-color: #ddd;
  cursor: pointer;
}
.table-admissiontest thead td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0d709a;
  color: white;
}
.table-admissiontest thead td a {
  color: white;
}
.table-admissiontest tbody #materialSent, .table-admissiontest tbody #taken, .table-admissiontest tbody #called, .table-admissiontest tbody #newAppointment {
  width: 1rem;
  text-align: center;
}
.table-admissiontest tr:nth-child(even) {
  background-color: #f2f2f2;
}
.table-admissiontest tr:hover {
  background-color: #ddd;
  cursor: pointer;
}
.table-admissiontest input {
  border-width: 1px;
  border-color: #ccc;
}
.table-admissiontest .date {
  width: 6em;
}
.table-admissiontest #time {
  width: 6em;
}
.table-admissiontest .grade {
  width: 4em;
}
.table-admissiontest .courseName {
  width: 2em;
}
.table-admissiontest .date {
  width: 120px;
}
.table-admissiontest .date-field {
  width: 120px;
}
.table-admissiontest .grade {
  width: 50px;
}
.table-admissiontest #advice {
  width: 200px;
  height: 100px;
  word-break: break-word;
}

.alert-too-young, .alert-tooltip {
  width: 100%;
  color: #721c24;
  background-color: #f8d7da;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  display: block;
}

.portal-overview .contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}
.portal-overview .contentHeader .user-menu {
  position: relative;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.portal-overview .contentHeader .user-menu img {
  margin-left: 5px;
  margin-right: 5px;
}
.portal-overview .contentHeader .user-menu:hover {
  background-color: #e6e6e6;
}
.portal-overview .contentHeader .user-menu input[name=showUserMenu] {
  display: none;
}
.portal-overview .contentHeader .user-menu input[name=showUserMenu]:checked ~ .user-submenu {
  display: block;
}
.portal-overview .contentHeader .user-menu .down {
  transition: transform 0.3s ease-in-out;
}
.portal-overview .contentHeader .user-menu input[name=showUserMenu]:checked ~ .down {
  transform: rotate(180deg);
}
.portal-overview .contentHeader .user-menu .user-submenu {
  display: none;
  position: absolute;
  background-color: white;
  top: 30px;
  border-radius: 5px;
  border: 1px solid #ccc;
  right: 0;
  width: 150px;
}
.portal-overview .contentHeader .user-menu .user-submenu a.account-link {
  color: #555555;
  text-decoration: none;
  font-size: 14px;
  display: block;
  padding: 7px;
}
.portal-overview .contentHeader .user-menu .user-submenu a.account-link:hover {
  background-color: #e6e6e6;
  color: #555555;
}
.portal-overview .information-tooltip {
  float: right;
  margin-top: -5px;
  margin-right: 3px;
}
.portal-overview .math-warning {
  color: #721c24;
}
.portal-overview input, .portal-overview select {
  flex: 2;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 7px 15px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .portal-overview {
    /* IE10+ specific styles go here */
  }
  .portal-overview input, .portal-overview select {
    flex: 2 0 auto;
  }
  .portal-overview #searchClearButton {
    flex: 2 0 100%;
  }
}
.portal-overview .contentSearchTextArea {
  margin-top: 25px;
  display: table;
  width: 100%;
}
.portal-overview .contentSearchTextArea span {
  display: table-cell;
  width: 1px;
  padding-right: 5px;
}
.portal-overview .contentSearchTextArea input {
  display: table-cell;
  width: 100%;
}
.portal-overview .contentSearchStatusArea {
  margin-top: 10px;
  padding-top: 10px;
}
.portal-overview .contentSearchStatusArea .label {
  display: flex;
}
.portal-overview .contentSearchStatusArea .statusList {
  display: block;
  column-count: 2;
}
.portal-overview .contentSearchStatusArea .statusItem {
  padding-right: 5px;
  justify-items: flex-end;
}
.portal-overview .contentSearchExtraArea {
  margin-top: 10px;
  display: flex;
  padding-top: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.portal-overview .contentSearchExtraArea > div {
  flex: 0 0 256px;
  margin-bottom: 20px;
}
.portal-overview .contentSearchExtraArea select {
  min-width: 227px;
}
.portal-overview .contentSearchExtraArea .contentSearchItem {
  min-height: 62px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}
.portal-overview .pagination {
  display: flex;
  padding-top: 10px;
  align-items: center;
  justify-content: space-between;
}
.portal-overview .pagination .paginationButton, .portal-overview .pagination .paginationCounter {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}
.portal-overview .contentSearchArea {
  margin-top: 10px;
}
.portal-overview .contentSearchArea span, .portal-overview .contentSearchArea label {
  font-weight: 600;
}
.portal-overview .contentSearchArea .label {
  margin-bottom: 0.5rem;
}
.portal-overview .contentSearchArea .statusItem span {
  font-weight: normal;
  padding-left: 5px;
  padding-right: 5px;
}
.portal-overview .contentSearchButtons {
  margin-top: 10px;
  display: flex;
}
.portal-overview .contentSearchButtons .searchButton {
  margin-right: 15px;
  max-width: 200px;
}
.portal-overview .table-wrap {
  width: 100%;
  overflow-x: auto;
}
.portal-overview .table {
  width: 100%;
  margin-top: 15px;
}
.portal-overview .table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.portal-overview .table tr:hover {
  background-color: #ddd;
  cursor: pointer;
}
.portal-overview .table td {
  padding: 0.5rem 1rem;
}
.portal-overview .table thead td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0d709a;
  color: white;
}
.portal-overview .table thead td a {
  color: white;
  text-decoration: none;
  padding-left: 3px;
}

.exportButtons {
  margin: 1rem 0;
}

.buttonload {
  color: #575757; /* White text */
  padding: 7px 15px; /* Some padding */
  font-size: 16px; /* Set a font-size */
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Add a right margin to each icon */
.fa {
  margin-left: -12px;
  margin-right: 8px;
}

.searchResult {
  text-align: center;
}

.information-tooltip {
  cursor: pointer;
  margin: 0.5rem 0 0 0.5rem;
}
.information-tooltip__icon {
  height: 16px;
  width: 16px;
  fill: #0d709a;
}
.information-tooltip__placeholder {
  height: 16px;
  width: 16px;
}
.information-tooltip__bubble {
  display: none;
  width: 230px;
  max-width: 230px;
  min-height: 105px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  transition: opacity 250ms ease-out;
  cursor: default;
  margin: 12px;
  z-index: 9999;
}
.information-tooltip__bubble:before {
  background-color: #fff;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -6px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%) rotate(45deg);
  z-index: 999;
}
.information-tooltip__bubble:after {
  background-color: #fff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -6px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%) rotate(45deg);
  z-index: -1;
}
.information-tooltip__bubble__title {
  font-weight: bold;
  color: #0d709a;
}
.information-tooltip__bubble__content {
  display: block;
  margin-top: 5px;
  color: #575757;
}
.information-tooltip__bubble__mask {
  display: block;
  top: 0;
  height: 100%;
  position: absolute;
  width: 16px;
  z-index: 9;
  background: #fff;
}
.information-tooltip__bubble[x-placement=right]:before, .information-tooltip__bubble[x-placement=right]:after {
  left: -6px;
  right: auto;
}
.information-tooltip__bubble[x-placement=right] .information-tooltip__bubble__mask {
  left: 0;
}
.information-tooltip__bubble[x-placement=left]:before, .information-tooltip__bubble[x-placement=left]:after {
  right: -6px;
  left: auto;
}
.information-tooltip__bubble[x-placement=left] .information-tooltip__bubble__mask {
  right: 0;
}
.information-tooltip__bubble--active {
  display: inline-block;
  pointer-events: all !important;
}
.information-tooltip .hour-padding {
  padding-left: 20px;
}

#popup-image {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7);
  padding-top: 5%;
}
#popup-image #close-popup {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #ffffff;
  font-size: 30px;
}
#popup-image #image-popup {
  margin: auto;
  display: block;
}

.multisteps-form__progress {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  margin-bottom: 35px;
}

.multisteps-form__progress-btn {
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  position: relative;
  max-width: 150px;
  padding-top: 20px;
  color: rgba(13, 112, 154, 0.7);
  text-indent: 0;
  border: none;
  background-color: transparent;
  outline: none !important;
  cursor: pointer;
}
.multisteps-form__progress-btn.length2 {
  margin-left: 25%;
}
@media only screen and (max-width: 970px) {
  .multisteps-form__progress-btn.length2 {
    display: none;
    width: 34%;
    text-indent: 0;
    overflow-wrap: break-word;
  }
}
.multisteps-form__progress-btn.length2:after {
  position: absolute;
  top: 5px;
  left: calc(-265% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 290%;
  height: 2px;
  margin-left: 25%;
  content: "";
  background-color: currentColor;
  z-index: 1;
}
.multisteps-form__progress-btn.length3 {
  margin-left: 10%;
}
@media only screen and (max-width: 950px) {
  .multisteps-form__progress-btn.length3 {
    display: none;
    width: 50%;
    text-indent: 0;
    overflow-wrap: break-word;
  }
}
.multisteps-form__progress-btn.length3:after {
  position: absolute;
  top: 5px;
  left: calc(-140% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 190%;
  height: 2px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}
@media only screen and (max-width: 950px) {
  .multisteps-form__progress-btn.length4 {
    display: none;
    width: 70%;
    text-indent: 0;
    overflow-wrap: break-word;
  }
}
.multisteps-form__progress-btn.length4:after {
  position: absolute;
  top: 5px;
  left: calc(-90% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 140%;
  height: 2px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}
@media only screen and (max-width: 910px) {
  .multisteps-form__progress-btn.length5 {
    display: none;
    width: 80%;
    text-indent: 0;
    overflow-wrap: break-word;
  }
}
.multisteps-form__progress-btn.length5:after {
  position: absolute;
  top: 5px;
  left: calc(-60% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 110%;
  height: 2px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}
.multisteps-form__progress-btn.length6 {
  overflow-wrap: break-word;
  text-indent: 0;
}
@media only screen and (max-width: 860px) {
  .multisteps-form__progress-btn.length6 {
    display: none;
  }
}
.multisteps-form__progress-btn.length6:after {
  position: absolute;
  top: 5px;
  left: calc(-50% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}
.multisteps-form__progress-btn.length7 {
  overflow-wrap: break-word;
  text-indent: 0;
}
@media only screen and (max-width: 860px) {
  .multisteps-form__progress-btn.length7 {
    display: none;
  }
}
.multisteps-form__progress-btn.length7:after {
  position: absolute;
  top: 5px;
  left: calc(-50% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}
.multisteps-form__progress-btn.length8 {
  overflow-wrap: break-word;
  text-indent: 0;
}
@media only screen and (max-width: 860px) {
  .multisteps-form__progress-btn.length8 {
    display: none;
  }
}
.multisteps-form__progress-btn.length8:after {
  position: absolute;
  top: 5px;
  left: calc(-50% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}
.multisteps-form__progress-btn.length9 {
  overflow-wrap: break-word;
  text-indent: 0;
}
@media only screen and (max-width: 860px) {
  .multisteps-form__progress-btn.length9 {
    display: none;
  }
}
.multisteps-form__progress-btn.length9:after {
  position: absolute;
  top: 5px;
  left: calc(-50% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}
.multisteps-form__progress-btn:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 13px;
  height: 13px;
  content: "";
  transform: translateX(-50%);
  transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  border: 2px solid currentColor;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 3;
}
.multisteps-form__progress-btn:first-child:after {
  display: none;
}
.multisteps-form__progress-btn.js-active {
  color: #0d709a;
  font-family: inherit;
  font-weight: bold;
}
.multisteps-form__progress-btn.js-active:before {
  transform: translateX(-50%) scale(1.2);
  background-color: currentColor;
}

/** Global Magnolia + flexbox fix */
.mgnlEditorBar.area, .mgnlPlaceholder {
  width: 100% !important;
}
.mgnlEditorBar.area.mgnlEditorBarStatusIndicator, .mgnlPlaceholder.mgnlEditorBarStatusIndicator {
  width: calc(100% - 28px) !important;
}