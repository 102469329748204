.portal-overview {
    .contentHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;

        .user-menu {
            position: relative;

            img {
                margin-left: 5px;
                margin-right: 5px;
            }

            font-size: 14px;
            display: flex;
            align-items: center;
            padding: 4px 10px;
            border-radius: 5px;
            border: 1px solid #ccc;
            cursor: pointer;

            &:hover {
                background-color: #e6e6e6;
            }

            input[name=showUserMenu] {
                display: none;
            }

            input[name=showUserMenu]:checked ~ .user-submenu {
                display: block;
            }

            .down {
                transition: transform .3s ease-in-out;
            }

            input[name=showUserMenu]:checked ~ .down {
                transform: rotate(180deg);
            }

            .user-submenu {
                display: none;
                position: absolute;
                background-color: white;
                top: 30px;
                border-radius: 5px;
                border: 1px solid #ccc;
                right: 0;
                width: 150px;

                a.account-link {
                    color: #555555;
                    text-decoration: none;
                    font-size: 14px;
                    display: block;
                    padding: 7px;

                    &:hover {
                        background-color: #e6e6e6;
                        color: #555555;
                    }
                }
            }
        }
    }

    .information-tooltip {
        float: right;
        margin-top: -5px;
        margin-right: 3px;
    }

    .math-warning {
        color: #721c24;
    }

    input, select {
        flex: 2;
        border-radius: $border-radius;
        border: 1px solid #ccc;
        padding: 7px 15px;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        /* IE10+ specific styles go here */
        input, select {
            flex: 2 0 auto;
        }
        #searchClearButton {
            flex: 2 0 100%;
        }
    }

    .contentSearchTextArea {
        margin-top: 25px;
        display: table;
        width: 100%;

        span {
            display: table-cell;
            width: 1px;
            padding-right: 5px;
        }

        input {
            display: table-cell;
            width: 100%;
        }
    }

    .contentSearchStatusArea {
        margin-top: 10px;
        padding-top: 10px;

        .label {
            display: flex;
        }

        .statusList {
            display: block;
            column-count: 2;
        }

        .statusItem {
            padding-right: 5px;
            justify-items: flex-end;
        }

    }

    .contentSearchExtraArea {
        margin-top: 10px;
        display: flex;
        padding-top: 10px;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        > div {
            flex: 0 0 256px;
            margin-bottom: 20px;
        }

        select {
            min-width: 227px;
        }

        .contentSearchItem {
            min-height: 62px;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            padding-right: 5px;
        }

    }

    .pagination {
        display: flex;
        padding-top: 10px;
        align-items: center;
        justify-content: space-between;

        .paginationButton, .paginationCounter {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            padding-right: 5px;
        }
    }

    .contentSearchArea {
        margin-top: 10px;

        span, label {
            font-weight: 600;
        }

        .label {
            margin-bottom: 0.5rem;
        }

        .statusItem span {
            font-weight: normal;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .contentSearchButtons {
        margin-top: 10px;
        display: flex;

        .searchButton {
            margin-right: 15px;
            max-width: 200px;
        }
    }

    .table-wrap {
        width: 100%;
        overflow-x: auto;
    }

    .table {
        width: 100%;
        margin-top: 15px;

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        tr:hover {
            background-color: #ddd;
            cursor: pointer;
        }

        td {
            padding: 0.5rem 1rem;
        }

        thead td {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: $primary-color;
            color: white;

            a {
                color: white;
                text-decoration: none;
                padding-left: 3px;
            }
        }
    }

}

.exportButtons {
    margin: 1rem 0;
}

.buttonload {

    color: #575757; /* White text */
    padding: 7px 15px; /* Some padding */
    font-size: 16px; /* Set a font-size */
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;

}

/* Add a right margin to each icon */
.fa {
    margin-left: -12px;
    margin-right: 8px;
}

.searchResult {
    text-align: center;
}
