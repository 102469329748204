$btn-offset-vert: 20px;
$btn-circle-decor-dimensions: 13px;

@mixin transition-mix($property: all, $duration: 0.2s, $timing: linear, $delay: 0s) {
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: $timing;
    transition-delay: $delay;
}

@mixin position-absolute($top: null, $left: null, $right: null, $bottom: null) {
    position: absolute;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
}

//multisteps progress styles
.multisteps-form__progress {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    margin-bottom: 35px;
}

.multisteps-form__progress-btn {
    @include transition-mix($duration: .15s);

    position: relative;
    max-width: 150px;
    padding-top: $btn-offset-vert;

    color: rgba($primary-color, .7);

    //text-indent: -9999px;
    text-indent: 0;
    border: none;
    background-color: transparent;
    outline: none !important;
    cursor: pointer;

    //TODO add more cases based on max components
    &.length2 {
        $leftVariable: calc(-265% - #{$btn-circle-decor-dimensions} / 2);
        $widthVariable: 290%;

        margin-left: 25%;

        @media only screen and (max-width: 970px) {
            display: none;
            width: 34%;
            text-indent: 0;
            overflow-wrap: break-word;
        }

        &:after {
            //20/4 ---- -50% - 13px / 2
            @include position-absolute($top: calc($btn-offset-vert / 4), $left: $leftVariable);
            @include transition-mix($duration: .15s);
            display: block;
            width: $widthVariable;
            height: 2px;
            margin-left: 25%;
            content: '';

            background-color: currentColor;

            z-index: 1;

        }
    }

    &.length3 {
        $leftVariable: calc(-140% - #{$btn-circle-decor-dimensions} / 2);
        $widthVariable: 190%;
        margin-left: 10%;

        @media only screen and (max-width: 950px) {
            display: none;
            width: 50%;
            text-indent: 0;
            overflow-wrap: break-word;
        }

        &:after {
            //20/4 ---- -50% - 13px / 2
            @include position-absolute($top: calc($btn-offset-vert / 4), $left: $leftVariable);
            @include transition-mix($duration: .15s);
            display: block;
            width: $widthVariable;
            height: 2px;

            content: '';

            background-color: currentColor;

            z-index: 1;

        }
    }

    &.length4 {
        $leftVariable: calc(-90% - #{$btn-circle-decor-dimensions} / 2);
        $widthVariable: 140%;

        @media only screen and (max-width: 950px) {
            display: none;
            width: 70%;
            text-indent: 0;
            overflow-wrap: break-word;
        }

        &:after {
            //20/4 ---- -50% - 13px / 2
            @include position-absolute($top: calc($btn-offset-vert / 4), $left: $leftVariable);
            @include transition-mix($duration: .15s);
            display: block;
            width: $widthVariable;
            height: 2px;

            content: '';

            background-color: currentColor;

            z-index: 1;

        }
    }

    &.length5 {
        $leftVariable: calc(-60% - #{$btn-circle-decor-dimensions} / 2);
        $widthVariable: 110%;

        @media only screen and (max-width: 910px) {
            display: none;
            width: 80%;
            text-indent: 0;
            overflow-wrap: break-word;
        }

        &:after {
            //20/4 ---- -50% - 13px / 2
            @include position-absolute($top: calc($btn-offset-vert / 4), $left: $leftVariable);
            @include transition-mix($duration: .15s);
            display: block;
            width: $widthVariable;
            height: 2px;

            content: '';

            background-color: currentColor;

            z-index: 1;

        }
    }

    &.length6 {
        $leftVariable: calc(-50% - #{$btn-circle-decor-dimensions} / 2);
        $widthVariable: 100%;
        overflow-wrap: break-word;
        text-indent: 0;

        @media only screen and (max-width: 860px) {
            display: none;
        }

        &:after {
            //20/4 ---- -50% - 13px / 2
            @include position-absolute($top: calc($btn-offset-vert / 4), $left: $leftVariable);
            @include transition-mix($duration: .15s);
            display: block;
            width: $widthVariable;
            height: 2px;
            content: '';

            background-color: currentColor;

            z-index: 1;

        }
    }

    &.length7 {
        $leftVariable: calc(-50% - #{$btn-circle-decor-dimensions} / 2);
        $widthVariable: 100%;
        overflow-wrap: break-word;
        text-indent: 0;

        @media only screen and (max-width: 860px) {
            display: none;
        }

        &:after {
            //20/4 ---- -50% - 13px / 2
            @include position-absolute($top: calc($btn-offset-vert / 4), $left: $leftVariable);
            @include transition-mix($duration: .15s);
            display: block;
            width: $widthVariable;
            height: 2px;
            content: '';

            background-color: currentColor;

            z-index: 1;

        }
    }

    &.length8 {
        $leftVariable: calc(-50% - #{$btn-circle-decor-dimensions} / 2);
        $widthVariable: 100%;
        overflow-wrap: break-word;
        text-indent: 0;

        @media only screen and (max-width: 860px) {
            display: none;
        }

        &:after {
            //20/4 ---- -50% - 13px / 2
            @include position-absolute($top: calc($btn-offset-vert / 4), $left: $leftVariable);
            @include transition-mix($duration: .15s);
            display: block;
            width: $widthVariable;
            height: 2px;
            content: '';

            background-color: currentColor;

            z-index: 1;

        }
    }

    &.length9 {
        $leftVariable: calc(-50% - #{$btn-circle-decor-dimensions} / 2);
        $widthVariable: 100%;
        overflow-wrap: break-word;
        text-indent: 0;

        @media only screen and (max-width: 860px) {
            display: none;
        }

        &:after {
            //20/4 ---- -50% - 13px / 2
            @include position-absolute($top: calc($btn-offset-vert / 4), $left: $leftVariable);
            @include transition-mix($duration: .15s);
            display: block;
            width: $widthVariable;
            height: 2px;
            content: '';

            background-color: currentColor;

            z-index: 1;

        }
    }

    //circle decoration
    &:before {
        @include position-absolute($top: 0, $left: 50%);

        display: block;
        width: $btn-circle-decor-dimensions;
        height: $btn-circle-decor-dimensions;

        content: '';

        transform: translateX(-50%);

        transition: all .15s linear 0s,
        transform .15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;

        border: 2px solid currentColor;
        border-radius: 50%;
        background-color: #fff;

        box-sizing: border-box;

        z-index: 3;
    }


    //last child - without line decoration
    &:first-child {

        &:after {
            display: none;
        }
    }


    //active styles
    &.js-active {
        color: $primary-color;
        font-family: inherit;
        font-weight: bold;

        &:before {
            transform: translateX(-50%) scale(1.2);

            background-color: currentColor;
        }
    }
}
