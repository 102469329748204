$alert-color: #ba5520; // alert color change here!
$alert-color-bg: rgba($alert-color, 0.20);

fieldset {
    border: none;
}

.disabled-row {
    .slot {
        background-color: grey;

        &[class*="used-"] {
            background-color: red;

            &.myAppointmentSlot,
            &.myRAppointmentSlot {
                background-color: #2aabd2;
            }
        }
    }
}
.legendaSlot {
    width: 15px;
    aspect-ratio: 1;
    margin: 0 5px 0 0;
    padding: 0;
    border-radius: 50rem;
    &.red {
        background-color: red;
    }
    &.green {
        background-color: green;
    }
    &.blue {
        background-color: #2aabd2;
    }
    &.orange {
        background-color: orange;
    }
    &.grey {
        background-color: grey;
    }
    &.lightgray {
        background-color: lightgray;
    }
    &.darkseagreen {
        background-color: darkseagreen;
    }
}

.slot {
    width: 20px;
    aspect-ratio: 1;
    background-color: green;
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 0;
}

.rutte {
    &.myRAppointmentSlot {
        background-color: #2aabd2;
    }

    &.disabledRutteSlot {
        background-color: grey;
    }
}
div[class*="used-"] {
    background-color: red;

    &.myAppointmentSlot {
        background-color: #2aabd2;
    }
}



.selected-slot,
.selected-rutte-slot {
    background-color: orange !important;
}



.input-option {
    margin: 0.1rem 0;

    input {
        cursor: pointer;
    }
}

.longtext {
    display: -webkit-inline-box !important;
    width: 75% !important;
}

.input-fileupload {
    display: flex;
    margin: 0.5rem 0;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;

    .label {
        display: block;
        margin: 0.1rem 0;
        flex: 1;
    }

    input {
        flex: 2;
        border-radius: $border-radius;
        border: 1px solid #ccc;
        padding: 7px 15px;
    }

    @include on(up-to-medium) {
        flex-flow: wrap;
        .label {
            flex-basis: 100%;
        }
    }
}


.input-text {
    display: flex;
    margin: 0.5rem 0;

    .label {
        display: block;
        margin: 0.1rem 0;
        flex: 1;
    }

    .bsnError {
        flex: 2;
    }

    input {
        flex: 2;
        border-radius: $border-radius;
        border: 1px solid #ccc;
        padding: 7px 15px;
    }

    @include on(up-to-medium) {
        flex-flow: wrap;
        .label {
            flex-basis: 100%;
        }
    }
}

.input-select {
    display: flex;
    margin: 0.5rem 0;

    .label {
        display: block;
        margin: 0.1rem 0;
        flex: 1;
    }

    select {
        flex: 2;
        border-radius: $border-radius;
        border: 1px solid #ccc;
        padding: 7px 15px;
    }

    @include on(up-to-medium) {
        flex-flow: wrap;
        .label {
            flex-basis: 100%;
        }
    }
}

.input-checkbox,
.input-radio {
    display: flex;
    margin: 0.5rem 0;

    .label {
        display: block;
        margin: 0.1rem 0;
        flex: 1;
    }

    .input-options {
        flex: 2;
    }

    @include on(up-to-medium) {
        flex-flow: wrap;
        .label {
            flex-basis: 100%;
        }
    }
}

.view-only {
    display: flex;
    margin: 0.5rem 0;

    .label {
        display: block;
        margin: 0.1rem 0;
        flex: 1;
    }

    .values {
        width: 600px;
        max-height: 400px;
    }
}


.oneProfile {
    font-weight: bold;
}

label {
    cursor: pointer;
}

input, select, textarea {
    font-size: inherit;
    font-family: inherit;
    border-radius: $border-radius;
}

textarea {
    width: 100%;
}

.content-section {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
}

.button-wrapper {
    padding: 20px 0;

    & .loader {
        border: 8px solid #f3f3f3;
        border-radius: 50%;
        border-top: 8px solid $button-background-submit;
        width: 40px;
        height: 40px;
        -webkit-animation: spin 1.5s linear infinite; /* Safari */
        animation: spin 1.5s linear infinite;
        display: none;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


input[type=submit],
input[type=reset],
input[type=button] {
    font-family: inherit;
    font-size: inherit;
    margin-top: 10px;
    padding: 7px 15px;
    border: 0 none;
    cursor: pointer;
    border-radius: $border-radius;
}

input[type=submit] {
    background-color: $button-background-submit;
    color: $button-text-submit;

    &:hover {
        background-color: darken($button-background-submit, 10%);
    }
}

input[type=reset],
input[type=button] {
    background-color: $button-background-reset;
    color: $button-text-reset;

    &:hover {
        background-color: darken($button-background-reset, 10%);
    }
}

input[type=submit]:disabled,
input[type=reset]:disabled,
input[type=button]:disabled,
input[type=button]:disabled.uploadButton {
    background-color: #DFDFDF;

    &:hover {
        background-color: #DFDFDF;
    }
}

input[type=button].uploadButton {
    background-color: $button-background-submit;
    color: $button-text-submit;

    &:hover {
        background-color: darken($button-background-submit, 10%);
    }

    max-width: 200px;
    margin: 0 0 0 20px;
}

.fileInput {
    border: none;
}

#loginForm {
    input[type='text'], input[type='password'] {
        width: 100%;
        height: 40px;
        display: inline-block;
        border: 1px solid #ccc;
        box-sizing: border-box;
        padding-left: 40px;
        background-color: transparent;
        font-size: 1em;
        border-radius: $border-radius;
    }

    .form-row {
        position: relative;

        & .input_img {
            position: absolute;
            bottom: 8px;
            left: 8px;
            width: 24px;
            height: 24px;
        }
    }

    .button-wrapper {
        margin-left: -12px;
    }

    label {
        display: none;
    }

    .odd {
        background-color: #9acfea;
    }

    .required {
        display: none;
    }

    a:link, a:visited, a:hover, a:active {
        color: #555555;
    }

    .registerForgotten {
        display: flex;
        flex-direction: row;

        & a {
            margin-right: 20px;
        }
    }

    .form-row-qr {
        width: 500px;
        margin: 0 auto;

        position: relative;

        & .input_img {
            position: absolute;
            bottom: 8px;
            left: 8px;
            width: 24px;
            height: 24px;
        }
    }

    .qr-container {
        width: 100%;
        display: flex;

        & canvas {
            margin: 0 auto;
        }
    }
}

.login-text {
    padding: 0px 30px;
}

#register-new-user, #forget-password, #reset-password, #profiel-beheer {
    input[type='password'], input[type='text'], input[type='email'] {
        width: 100%;
        height: 40px;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: $border-radius;
        box-sizing: border-box;
        padding-left: 10px;
        background-color: transparent;
        font-size: 1em;
    }
}

.information-tooltip-right {
    float: right;
}

.overview {

    table {
        width: 100%;
    }

    td {
        width: 50%;
    }

    & .course {
        display: flex;
        justify-content: space-evenly;
    }

    & .title.profiel {
        margin: 18px 0 0;
    }

    tr:nth-child(even) {
        background-color: #ffffff;
    }

    tr:nth-child(odd) {
        background-color: #f2f2f2;
    }

    & .column {
        padding: 7px 15px;
        width: 100%;

        & h4 {
            padding: 7px 0px;
            margin: 0px;
        }

        & label {
            text-align: left;
        }

        & .center-block {
            text-align: center;
            width: 100%;
            display: block;
        }
    }
}

.course-config {
    & .title {
        padding-top: 20px;
        padding-bottom: 20px;
        color: $primary-color;
        font-family: sans-serif;
        font-weight: 700;
        display: block;
    }

    & select {
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 0.5rem 1rem;
        //appearance: none;
    }

    & .column {
        select {
            width: 100%;
        }
    }

    & .course {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > input, > select {
            flex: 0 0 auto;
        }
    }

    & .column {
        padding: 0.5rem 1rem;

        & .center-block {
            text-align: center;
            width: 100%;
            display: block;
        }

        & .headItem {
            font-weight: 700;
        }
    }

    .centered {
        text-align: center;
    }

    & .column.column-courses {
        flex: 3;
    }

    & .column.column-traject {
        flex: 1;
    }

    & .column.column-grade {
        flex: 1;
    }

    & .column.column-checkbox {
        flex: 1;
    }

    & .column.column-endgrade {
        flex: 1;
    }


    & .column.column-endgrade-warning {
        flex: 1;
        background-color: $alert-color-bg;
    }

    & .grade-warning {
        color: $alert-color;
        width: 100%;
        text-align: right;
    }

    .optCoursePoint {
        max-width: 400px;
        margin-left: -350px;
        overflow: hidden;
    }

    .hide-dots {
        .optCoursePoint {
            display: none;
        }
    }

    .hide-profile {
        .title.configuratie.ng-binding {
            display: none;
        }
    }
}

@media only screen and (max-width: 780px) {
    .course-config {
        .optCoursePoint {
            max-width: 250px;
            overflow: hidden;
        }
    }
}

@media only screen and (max-width: 500px) {
    .container {
        padding: 20px 13px;
    }
    .course-config {
        & .column {
            max-width: 110%;
        }

        .centered {
            max-width: 40px;
        }

        .vak {
            max-width: 175px;
            padding: 2px 2px;
        }

        .cijfer {
            max-width: 75px;
        }

        .volgen {
            max-width: 65px;
        }

        .course select {
            max-width: 75px;
        }

        & .column.column-courses {
            max-width: 175px;
        }

        & .column.column-traject {
            padding: 2px 2px;
        }

        & .column.column-grade {
            padding: 2px 2px;
        }

        & .column.column-checkbox {
            max-width: 75px;
        }

        .optCoursePoint {
            display: none;
        }
    }
}

.math-warning {
    color: #721c24;
}

@media only screen and (max-width: 350px) {
    .container {
        padding: 20px 13px;
    }
    .course-config {
        & .column {
            max-width: 110%;
        }

        .centered {
            max-width: 30px;
        }

        .vak {
            max-width: 170px;
            padding: 2px 2px;
        }

        .cijfer {
            max-width: 75px;
        }

        .volgen {
            max-width: 63px;
        }

        .course select {
            max-width: 75px;
        }

        & .column.column-courses {
            max-width: 220px;
            padding: 0px;
        }

        & .column.column-traject {
            padding: 2px 0px;
        }

        & .column.column-grade {
            padding: 2px 0px;
        }

        & .column.column-checkbox {
            max-width: 75px;
        }

        .optCoursePoint {
            display: none;
        }
    }
}

.bdf.course-config {
    .profileSelect {
        padding: 7px 15px;
    }

    .odd {
        background-color: lightgray;
    }

    .even {
        background-color: #ffffff;
    }

    .column {
        padding: 0px 0px;
        margin: 2px 4px;
    }

    .column.column-courses {
        flex: 3;
        display: flex;
        align-items: center;
    }

    .column.column-traject {
        flex: 2;
    }

    .column.column-grade {
        flex: 1;
    }

    .column.column-checkbox {
        flex: 1;
    }

    .column.column-endgrade {
        flex: 1;
    }

    & select {
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 0px 10px 0px 0px;
    }


    & input[type="text"] {
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 7px 15px;
        width: 100%;
    }

    #GemeenschappelijkdeelOptie7checkbox {
        @media (min-width: $medium) {
            max-width: 77px;
        }
    }

}

.enrolment-page-courseOverview {
    & table {
        width: 100%;

        & th {
            text-align: left;
        }

        & .profile {
            padding-top: 10px;
            background-color: #EFEFEF;
        }
    }
}

.enrolment-step {
    & .downloadFormContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & .downloadForms {
            margin-right: 10px;
        }
    }

    // notification
    & .downloadNotification {
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparentize($primary-color, 0.2);
        align-items: center;
        display: none;
        justify-content: center;

        & .notificationContainer {
            justify-content: center;
            align-content: initial;
            align-items: center;
            background-color: white;
            padding: 30px;
            border-radius: 5px;
            flex-wrap: wrap;

            & .notificationContent {
                overflow: auto;
                width: 750px;
                justify-content: center;

                & .downloadNotificationTitle {
                    display: flex;
                    justify-content: center;
                }
            }

            & .notificationButtonContainer {
                display: flex;
                justify-content: center;
                align-items: center;

                #closeNotification {
                    background-color: $primary-color;
                    color: white;
                }
            }
        }
    }
}



.enrolment-edit {
    & .title {
        padding-top: 20px;
        padding-bottom: 20px;
        color: $primary-color;
        font-family: sans-serif;
        font-weight: 700;
        display: block;
    }

    & .studentInfo, .studentRemarks, .intake, .planning {
        display: flex;
        flex-direction: column;
    }

    & .planning {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    & .informationRow {
        padding-bottom: 5px;
        width: 100%;
        min-height: 40px;
        display: flex;
        justify-content: left;
        align-content: center;
        align-items: center;

        & p {
            margin: 0;
        }

        & p:first-child {
            width: 200px;
        }

        & textarea, input, select {
            width: calc(100% - 200px);
            flex: 2;
            border-radius: $border-radius;
            border: 1px solid #ccc;
            padding: 7px 15px;
        }

        & .deleteRemarkText {
            font-weight:bold;
            border: none;
            background-color: transparent;
        }



        & table {
            width: calc(100% - 200px);

            & td {
                padding: 0px 5px;
            }

            & td:first-child{
                width: 100%;
            }

        }

        .warning {
            font-size: 14px;
            color: $alert-color;
            margin-top: 8px;
        }

        .check {
            color: #008701;
        }

        .cross {

            color: rgba(255, 0, 0, 0.0);
        }


        .marginleft {
            margin-left: 15px;
        }

    }

    & .informationRow input ,
    & .informationRow select{
        width: auto;
    }

    & .statusChoices {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 200px);
        padding-bottom: 5px;

        & .input-option {
            flex: 1 0 50%;
        }
    }

    & .statusListColumn {
        display: block;
        column-count: 2;

        .statusItem {
            padding-right: 5px;
            justify-items: flex-end;
        }
    }

    /* The Modal (background) */
    & .statusChangeModal {
        z-index: 2; /* Sit on top */
        position: fixed; /* Stay in place */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        background-color: transparentize($primary-color, 0.2);
        align-items: center;
        display: none; /* Hidden by default */
        justify-content: center;
        overflow: auto; /* Enable scroll if needed */
    }

    /* Modal Content/Box */
    & .statusChangeModal-content {
        justify-content: center;
        align-content: initial;
        align-items: center;
        background-color: white;
        padding: 30px;
        border-radius: 5px;
        flex-wrap: wrap;
        margin: 15% auto; /* 15% from the top and centered */
        border: 1px solid #888;
        width: 45%; /* Could be more or less, depending on screen size */

        .statusChangeButtons{
            display: flex;
            justify-content: space-around;
            width: 500px;

            .statusChangeButton{
                background-color: $button-background-submit;
                color: $button-text-submit;

                border: 0;
                border-radius: 5px;
                cursor: pointer;
                font-family: inherit;
                font-size: inherit;
                margin-top: 10px;
                padding: 7px 15px;

                &:hover {
                    background-color: darken($button-background-submit, 10%);
                }
            }
        }
    }

    & hr {
        padding-top: 2px;
        padding-bottom: 2px;
        background: $primary-color;
        border: 0;
    }

    & input[type=button] {
        background-color: $button-background-submit;
        color: $button-text-submit;

        &:hover {
            background-color: darken($button-background-submit, 10%);
        }
    }

    & input[type=submit]:disabled,
    input[type=reset]:disabled,
    input[type=button]:disabled,
    input[type=button]:disabled.uploadButton {
        background-color: #DFDFDF;

        &:hover {
            background-color: #DFDFDF;
        }
    }

    & .button-wrapper {
        display: flex;
        justify-content: space-between;
    }

    & .mailTemplate {
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparentize($primary-color, 0.2);
        align-items: center;
        display: none;
        justify-content: center;

        & .mailContainer {
            justify-content: center;
            align-content: initial;
            align-items: center;
            background-color: white;
            padding: 30px;
            border-radius: 5px;
            flex-wrap: wrap;

            & .mailContent {
                overflow: auto;
                width: 750px;
                height: 500px;
                justify-content: center;
            }
        }

        & .mailButtonContainer {
            display: flex;
            justify-content: space-around;
            width: 500px;
        }
    }
}

//Crappy hack for registration errors because of limitation within pur.
.form-row.error {
    & label span em {
        display: none;
    }
}

@mixin checkboxtd() {
    width: 1rem;
    text-align: center;
}


.table {
    width: 100%;
    margin-top: 15px;

    thead td {

        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: $primary-color;
        color: white;

        a {
            color: white;
        }
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    tr:hover {
        background-color: #ddd;
        cursor: pointer;
    }

    &-admissiontest {

        thead {
            td {

                padding-top: 12px;
                padding-bottom: 12px;
                text-align: left;
                background-color: $primary-color;
                color: white;

                a {
                    color: white;
                }
            }
        }

        tbody {
            #materialSent, #taken, #called, #newAppointment {
                @include checkboxtd;
            }
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        tr:hover {
            background-color: #ddd;
            cursor: pointer;
        }

        input {
            border-width: 1px;
            border-color: #ccc;
        }

        .date {
            width: 6em;
        }

        #time {
            width: 6em;
        }

        .grade {
            width: 4em;
        }

        .courseName {
            width: 2em;
        }

        .date {
            width: 120px;
        }

        .date-field {
            width: 120px;
        }


        .grade {
            width: 50px;
        }

        #called {
        }

        #advice {
            width: 200px;
            height: 100px;
            word-break: break-word;
        }
    }
}

.alert-too-young, .alert-tooltip {
    width: 100%;
    color: #721c24;
    background-color: #f8d7da;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid #f5c6cb;
    border-radius: .25rem;
    display: block;
}

